import React, { useMemo, useState } from "react";

import Image from "next/image";
import tw from "twin.macro";

import { useAuth } from "@/app/auth/hooks/useAuth";
import { routes } from "@/app/core/http/routes";
import { styled } from "@/app/core/styles/stitches.config";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";

const UserAvatarCircle = styled(Flex, {
  ...tw`rounded-full items-center justify-center overflow-hidden flex-shrink-0 relative`,
  variants: {
    size: {
      xs: tw`w-[1.85rem] h-[1.85rem] text-[0.6rem] font-bold`,
      sm: tw`w-3.5 h-3.5 text-sm font-bold`,
      md: tw`w-4 h-4 text-md font-bold`,
      lg: tw`w-8 h-8 text-2xl font-bold`,
      xl: tw`w-[9rem] h-[9rem] lg:(w-[10rem] h-[10rem]) text-2xl font-bold`,
      none: {},
    },
    background: {
      solid: tw`bg-gray-200 text-gray-800`,
      gradient: tw`relative text-white`,
      none: {},
    },
  },
  defaultVariants: {
    size: "sm",
    background: "solid",
  },
});

interface UserAvatarProps extends React.ComponentProps<typeof UserAvatarCircle> {
  from?: string;
  fromUserId?: number;
  hideContent?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  from,
  fromUserId,
  children,
  hideContent = false,
  background,
  ...props
}) => {
  const avatarContent = useMemo(() => {
    let content = from;

    if (content && content.replace("  ", " ").split(" ").length > 1) {
      content =
        content.replace("  ", " ").split(" ")[0][0] +
        content.replace("  ", " ").split(" ")[1][0];
    } else if (content && content.length > 1) {
      content = content[0] + content[1];
    } else if (content?.length) {
      content = content[0];
    }

    if (
      content &&
      content.length === 2 &&
      !/[A-Za-z]/g.test(content[1]) &&
      /[A-Za-z]/g.test(content[0])
    ) {
      content = content[0] + content[0];
    }

    return content;
  }, [from]);

  const { isAuthed } = useAuth();
  const [hasImageError, setHasImageError] = useState(false);

  return (
    <UserAvatarCircle background={hasImageError ? "solid" : background} {...props}>
      {((background === "gradient" && avatarContent) || fromUserId !== undefined) && (
        <Image
          tw="absolute left-0 top-0 w-full h-full"
          src={
            fromUserId !== undefined && isAuthed
              ? routes.api.userProfilePicture(fromUserId) + "?r=oblido"
              : `https://avatar.tobi.sh/${avatarContent?.toLowerCase()}`
          }
          alt="Foto de perfil"
          css={{
            objectFit: "cover",
            ...(fromUserId === undefined || !isAuthed ? tw`opacity-50` : {}),
            ...(hasImageError ? tw`opacity-0` : {}),
          }}
          fill
          onError={() => setHasImageError(true)}
        />
      )}

      {!hideContent && fromUserId === undefined && (
        <Text nowrap tw="relative z-[5] [text-shadow: 0px 0px 10px rgba(0,0,0,0.25)]">
          {avatarContent}
        </Text>
      )}

      {children}
    </UserAvatarCircle>
  );
};
