import React from "react";

import { FloatingPortal, useMergeRefs } from "@floating-ui/react";
import { AnimatePresence, motion } from "framer-motion";

import { TooltipElement } from "@/app/core/display/tooltip/components/TooltipElement";
import { useTooltipState } from "@/app/core/display/tooltip/hooks/useTooltipState";

interface TooltipContentProps extends React.ComponentProps<typeof TooltipElement> {}

export const TooltipContent = React.forwardRef<HTMLDivElement, TooltipContentProps>(
  (props, propRef) => {
    const state = useTooltipState();
    const ref = useMergeRefs([state.refs.setFloating, propRef]);

    return (
      <FloatingPortal>
        <AnimatePresence>
          {state.open && (
            <TooltipElement
              as={motion.div}
              ref={ref}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, delay: 0.3 }}
              style={{
                position: state.strategy,
                top: state.y ?? 0,
                left: state.x ?? 0,
                visibility: state.x === null ? "hidden" : "visible",
                ...props.style,
              }}
              {...state.getFloatingProps(props)}
            />
          )}
        </AnimatePresence>
      </FloatingPortal>
    );
  }
);

TooltipContent.displayName = "TooltipContent";
