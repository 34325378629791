import React, { cloneElement, isValidElement } from "react";

import { useMergeRefs } from "@floating-ui/react";

import { useTooltipState } from "@/app/core/display/tooltip/hooks/useTooltipState";

interface TooltipTriggerProps extends React.HTMLProps<HTMLElement> {
  asChild?: boolean;
}

export const TooltipTrigger = React.forwardRef<HTMLElement, TooltipTriggerProps>(
  ({ children, asChild = false, ...props }, propRef) => {
    const state = useTooltipState();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef]);

    // `asChild` allows any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        state.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          "data-state": state.open ? "open" : "closed",
        })
      );
    }

    return (
      <button
        type="button"
        ref={ref}
        // style the trigger based on the state
        data-state={state.open ? "open" : "closed"}
        tw="text-left"
        {...state.getReferenceProps(props)}
      >
        {children}
      </button>
    );
  }
);

TooltipTrigger.displayName = "TooltipTrigger";
