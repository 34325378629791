import React, { useCallback, useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import type {
  Control,
  DeepPartial,
  DefaultValues,
  FieldErrors,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegisterReturn,
  UseFormReset,
} from "react-hook-form";
import { useForm } from "react-hook-form";
import type { z } from "zod";

type Register<T extends FieldValues, P extends FieldPath<T> = FieldPath<T>> = (
  name: P,
  options?: RegisterOptions<T, P>
) => UseFormRegisterReturn<P> & { error: FieldErrors<T>[P] };

export interface SubmitActions<T extends z.ZodTypeAny> {
  getDirtyValues: () => DeepPartial<z.infer<T>>;
  reset: UseFormReset<z.infer<T>>;
}

interface FormProps<T extends z.ZodTypeAny>
  extends Omit<React.FormHTMLAttributes<HTMLFormElement>, "children" | "onSubmit"> {
  schema: T;
  onSubmit: (data: z.infer<T>, actions: SubmitActions<T>) => void | Promise<void>;
  initial?: DefaultValues<z.infer<T>>;
  reinitialize?: boolean;
  skipRequired?: FieldPath<z.infer<T>>[];
  children: (helpers: {
    register: Register<z.infer<T>>;
    isLoading: boolean;
    isValid: boolean;
    getValues: UseFormGetValues<z.infer<T>>;
    control: Control<z.infer<T>>;
  }) => JSX.Element;
}

export const Form = <T extends z.ZodTypeAny>({
  schema,
  onSubmit,
  initial,
  children,
  reinitialize,
  ...props
}: FormProps<T>) => {
  const { register, handleSubmit, formState, getValues, control, reset } = useForm<
    z.infer<T>
  >({
    resolver: zodResolver(schema),
    defaultValues: initial,
  });

  useEffect(() => {
    if (!reinitialize || !initial) {
      return;
    }

    reset(initial);
  }, [initial, reinitialize, reset]);

  /**
   * From all values, pick only those that are dirty.
   *
   * Note: This is recursive.
   *
   * @param dirty - The dirty values coming from the formState
   * @param values - All form values
   */
  const getDirtyValues = useCallback(
    (
      dirty: Partial<z.infer<T>> | unknown[],
      values: z.infer<T>
    ): DeepPartial<z.infer<T>> => {
      if (dirty === true || Array.isArray(dirty)) {
        return values;
      }

      return Object.fromEntries(
        Object.keys(dirty).map((key) => [
          key,
          getDirtyValues(dirty[key] as any, values[key]),
        ])
      ) as DeepPartial<z.infer<T>>;
    },
    []
  );

  /**
   * Wrapped the given onSubmit method
   * and inject the dirty values getter
   */
  const wrappedSubmit = useCallback(
    (data: z.infer<T>) =>
      onSubmit(data, {
        getDirtyValues: () => getDirtyValues(formState.dirtyFields, data),
        reset,
      }),
    [formState.dirtyFields, getDirtyValues, onSubmit, reset]
  );

  const customRegister: Register<z.infer<T>> = useCallback(
    (name, options) => {
      return {
        ...register(name, options),
        error: formState.errors[name],
        required: !(schema as any)?.shape?.[name]?.isOptional?.(),
      };
    },
    [formState.errors, register, schema]
  );

  return (
    <form onSubmit={handleSubmit(wrappedSubmit)} {...props}>
      {children({
        control,
        getValues,
        isValid: formState.isValid,
        isLoading: formState.isSubmitting || formState.isValidating,
        register: customRegister,
      })}
    </form>
  );
};
